import React, { useState } from "react";
import { db } from "../firebaseConfig";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";
import { motion } from "framer-motion";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
} from "@mui/material";
import "../styles/RSVP.css";
import { guests } from "../constants";

const RSVP = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    attending: "",
    severeAllergies: "",
    comments: "",
  });
  const [plusOneData, setPlusOneData] = useState({
    name: "",
    email: "",
    status: "",
  });
  const [rsvpId, setRsvpId] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [lookupOpen, setLookupOpen] = useState(false);
  const [lookupData, setLookupData] = useState({ name: "", email: "" });
  const [showPlusOneFields, setShowPlusOneFields] = useState(false);
  const [plusOneAllowed, setPlusOneAllowed] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  const checkGuestInList = (name) => {
    const guest = guests.find(
      (g) => g.name.toLowerCase() === name.toLowerCase(),
    );
    if (guest) {
      if (guest.plusOne) {
        setPlusOneAllowed(true);
        setShowPlusOneFields(true); // Show plus one fields
      } else {
        setPlusOneAllowed(false);
        setShowPlusOneFields(false); // Hide plus one fields
        setSnackbarMessage(
          "We are sorry, you do not have a +1 allocated. Please clear the form and try again. If this doesn't work, please contact Kelly (224-567-2254) or Ben (630-723-1447).",
        );
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
      }
    } else {
      setPlusOneAllowed(false);
      setShowPlusOneFields(false); // Hide plus one fields
      setSnackbarMessage("Guest not found in the list.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    if (name === "attending" && value === "yes") {
      checkGuestInList(formData.name);
    } else if (name === "attending" && value === "no") {
      setShowPlusOneFields(false); // Hide plus one fields if not attending
    }
  };

  const handleLookupChange = (e) => {
    const { name, value } = e.target;
    setLookupData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePlusOneChange = (e) => {
    const { name, value } = e.target;
    setPlusOneData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setConfirmationOpen(true);
  };

  const handleConfirmSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    try {
      if (!editMode) {
        // Check if the RSVP already exists
        const rsvpQuery = query(
          collection(db, "rsvps"),
          where("name", "==", formData.name),
        );
        const querySnapshot = await getDocs(rsvpQuery);

        if (!querySnapshot.empty) {
          setSnackbarMessage(
            "RSVP already exists for this name. Please use the 'Look Up RSVP' option to update your RSVP.",
          );
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          return;
        }

        // Check if the name is already listed as a plus one
        const plusOneQuery = query(
          collection(db, "rsvps"),
          where("plusOneData.name", "==", formData.name),
        );
        const plusOneSnapshot = await getDocs(plusOneQuery);

        if (!plusOneSnapshot.empty) {
          setSnackbarMessage(
            "This name is already listed as a plus one. Please use the 'Update RSVP' option.",
          );
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          return;
        }
      }

      if (editMode && rsvpId) {
        const rsvpDoc = doc(db, "rsvps", rsvpId);
        await updateDoc(rsvpDoc, { ...formData, plusOneData });
        setSnackbarMessage("RSVP updated!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        await addDoc(collection(db, "rsvps"), { ...formData, plusOneData });
        setSnackbarMessage("RSVP submitted!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      }
      clearFormData();
    } catch (error) {
      console.error("Error submitting RSVP: ", error);
      setSnackbarMessage("Error submitting RSVP. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
    setConfirmationOpen(false);
  };

  const handleLookup = async (e) => {
    e.preventDefault();
    try {
      const rsvpQuery = query(
        collection(db, "rsvps"),
        where("name", "==", lookupData.name),
        where("email", "==", lookupData.email),
      );
      const querySnapshot = await getDocs(rsvpQuery);
      if (!querySnapshot.empty) {
        const rsvpDoc = querySnapshot.docs[0];
        setRsvpId(rsvpDoc.id);
        setFormData(rsvpDoc.data());
        setEditMode(true);
        setLookupOpen(false);
      } else {
        setSnackbarMessage("RSVP not found");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error looking up RSVP: ", error);
      setSnackbarMessage("Error looking up RSVP. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const openLookupModal = () => {
    setLookupOpen(true);
  };

  const closeLookupModal = () => {
    setLookupOpen(false);
  };

  const clearFormData = () => {
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
      attending: "",
      severeAllergies: "",
      comments: "",
    });
    setPlusOneData({
      name: "",
      email: "",
      status: "",
    });
    setEditMode(false);
    setRsvpId("");
    setShowPlusOneFields(false); // Hide plus one fields
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <motion.div className="rsvp-page">
      <Container className="rsvp-container">
        <Typography variant="h2" className="rsvp-title">
          RSVP
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            name="name"
            label="Your Name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            className="rsvp-textfield"
          />
          <TextField
            name="email"
            label="Your Email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            className="rsvp-textfield"
          />
          <TextField
            name="phoneNumber"
            label="Your Phone Number"
            value={formData.phoneNumber}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            className="rsvp-textfield"
          />
          <FormControl fullWidth margin="normal" className="rsvp-formcontrol">
            <InputLabel>Are you attending?</InputLabel>
            <Select
              name="attending"
              value={formData.attending}
              onChange={handleChange}
              required
              className="rsvp-select"
            >
              <MenuItem value="yes">Yes, I accept</MenuItem>
              <MenuItem value="no">No, I decline</MenuItem>
            </Select>
          </FormControl>
          {showPlusOneFields && (
            <>
              <TextField
                name="name"
                label="Plus One Name"
                value={plusOneData.name}
                onChange={handlePlusOneChange}
                fullWidth
                required
                margin="normal"
                className="rsvp-textfield"
              />
              <TextField
                name="email"
                label="Plus One Email"
                type="email"
                value={plusOneData.email}
                onChange={handlePlusOneChange}
                fullWidth
                required
                margin="normal"
                className="rsvp-textfield"
              />
              <FormControl
                fullWidth
                margin="normal"
                className="rsvp-formcontrol"
              >
                <InputLabel>Plus One Status</InputLabel>
                <Select
                  name="status"
                  value={plusOneData.status}
                  onChange={handlePlusOneChange}
                  required
                  className="rsvp-select"
                >
                  <MenuItem value="accept">Accept</MenuItem>
                  <MenuItem value="decline">Decline</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
          <TextField
            name="severeAllergies"
            label="Severe Allergies"
            value={formData.severeAllergies}
            onChange={handleChange}
            fullWidth
            margin="normal"
            className="rsvp-textfield"
          />
          <TextField
            name="comments"
            label="Comments"
            value={formData.comments}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
            className="rsvp-textfield"
          />
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="rsvp-button rsvp-button-containedPrimary"
            >
              {editMode ? "Update RSVP" : "Submit RSVP"}
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={openLookupModal}
              className="rsvp-button rsvp-button-outlinedSecondary"
            >
              Look Up RSVP
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="default"
              onClick={clearFormData}
              className="rsvp-button rsvp-button-outlinedDefault"
            >
              Clear
            </Button>
          </Box>
        </form>
      </Container>
      <Dialog
        open={lookupOpen}
        onClose={closeLookupModal}
        className="rsvp-dialog"
      >
        <DialogTitle className="rsvp-dialog-title">Look Up RSVP</DialogTitle>
        <DialogContent>
          <form onSubmit={handleLookup}>
            <TextField
              name="name"
              label="Your Name"
              value={lookupData.name}
              onChange={handleLookupChange}
              fullWidth
              required
              margin="normal"
              className="rsvp-textfield"
            />
            <TextField
              name="email"
              label="Your Email"
              type="email"
              value={lookupData.email}
              onChange={handleLookupChange}
              fullWidth
              required
              margin="normal"
              className="rsvp-textfield"
            />
            <DialogActions>
              <Button
                onClick={closeLookupModal}
                sx={{
                  backgroundColor: "black",
                  width: "50%",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid black",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{
                  backgroundColor: "black",
                  width: "50%",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid black",
                  },
                }}
              >
                Look Up
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
      >
        <DialogTitle>Confirm Your Details</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Please confirm your details before submitting:
          </Typography>
          <Typography variant="body2">Name: {formData.name}</Typography>
          <Typography variant="body2">Email: {formData.email}</Typography>
          <Typography variant="body2">
            Phone Number: {formData.phoneNumber}
          </Typography>
          <Typography variant="body2">
            Attending: {formData.attending}
          </Typography>
          {plusOneAllowed && (
            <>
              <Typography variant="body2">
                Plus One Name: {plusOneData.name}
              </Typography>
              <Typography variant="body2">
                Plus One Email: {plusOneData.email}
              </Typography>
              <Typography variant="body2">
                Plus One Status: {plusOneData.status}
              </Typography>
            </>
          )}
          <Typography variant="body2">
            Severe Allergies: {formData.severeAllergies}
          </Typography>
          <Typography variant="body2">Comments: {formData.comments}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmationOpen(false)}
            sx={{
              backgroundColor: "black",
              width: "50%",
              color: "white",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                border: "1px solid black",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmSubmit}
            sx={{
              backgroundColor: "black",
              width: "50%",
              color: "white",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                border: "1px solid black",
              },
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="rsvp-snackbar"
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </motion.div>
  );
};

export default RSVP;
