import React from "react";
import { motion } from "framer-motion";
import { Typography, Container, Box } from "@mui/material";
import "../styles/OurStory.css";
import coupleImage1 from "../assets/images/holdingHandsInGarden.jpg";
import coupleImage2 from "../assets/images/holdingHandsGardenLookingAtEachOther.jpg";
import coupleImage3 from "../assets/images/kissyInGarden.jpg";

const OurStory = () => {
  return (
    <motion.div
      className="our-story-page"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <Container className="our-story-container">
        <Typography variant="h1" className="our-story-title">
          Our Love Story Began on Hinge...
        </Typography>
        <Typography variant="h6" className="our-story-subtitle">
          The App Designed to be Deleted
        </Typography>
        <Typography variant="body1" className="our-story-text">
          Ben and Kelly met in May of 2021 on Hinge! Ben must have already known
          the way to Kelly's heart because he started the conversation by asking
          her, "What's your favorite food?" They talked on the phone, FaceTimed,
          and texted until Ben drove out to Lake Zurich in late July to meet
          Kelly in person for the first time! They both went back to Iowa City
          for their junior years of college, and their relationship continued to
          grow. Throughout their last two years of college, they created many
          amazing memories together, from dinner dates to Pop's Barbecue to
          making weekend trips to Wisconsin with friends. After graduating in
          May 2023, Kelly started as a nurse in the Pediatric Intensive Care
          Unit at UI Children's Hospital, and Ben pursued his Master's degree in
          Electrical and Computer Engineering. About a year post-grad, on June
          5th, 2024, Ben asked Kelly to marry him at their special spot on the
          overlook of Lake McBride, and she said YES!!
        </Typography>
        <div className="our-story-images">
          <img
            src={coupleImage1}
            alt="Ben and Kelly"
            className="our-story-image"
          />
          <img
            src={coupleImage2}
            alt="Ben and Kelly"
            className="our-story-image"
          />
          <img
            src={coupleImage3}
            alt="Ben and Kelly"
            className="our-story-image"
          />
        </div>
        <Box className="our-story-footer">
          <Typography variant="h4" className="our-story-initials">
            K &B
          </Typography>
          <div className="our-story-divider"></div>
          <Typography variant="h6" className="our-story-date">
            08.16.2025
          </Typography>
        </Box>
      </Container>
    </motion.div>
  );
};

export default OurStory;
