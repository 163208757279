import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import "../styles/Navbar.css";

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const drawerRef = useRef(null);
  const { currentUser } = useAuth();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      closeDrawer();
    }
  };

  useEffect(() => {
    if (drawerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [drawerOpen]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-content">
        <ul className="nav-links">
          {windowWidth > 768 && (
            <>
              <li>
                <Link
                  to="/"
                  className={location.pathname === "/" ? "active" : ""}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/our-story"
                  className={location.pathname === "/our-story" ? "active" : ""}
                >
                  Our Story
                </Link>
              </li>
              <li>
                <Link
                  to="/travel"
                  className={location.pathname === "/travel" ? "active" : ""}
                >
                  Travel
                </Link>
              </li>
              <li>
                <Link
                  to="/faq"
                  className={location.pathname === "/faq" ? "active" : ""}
                >
                  FAQ
                </Link>
              </li>
            </>
          )}
        </ul>
        <Typography variant="h1" className="logo">
          K &B
        </Typography>
        <IconButton className="drawer-toggle" onClick={toggleDrawer}>
          <MenuIcon style={{ fontSize: "2.5em" }} />
        </IconButton>
      </div>
      <div ref={drawerRef} className={`drawer ${drawerOpen ? "open" : ""}`}>
        <ul>
          {windowWidth <= 768 && (
            <>
              <li className="drawer-only">
                <Link
                  to="/"
                  className={location.pathname === "/" ? "active" : ""}
                  onClick={closeDrawer}
                >
                  Home
                </Link>
              </li>
              <li className="drawer-only">
                <Link
                  to="/our-story"
                  className={location.pathname === "/our-story" ? "active" : ""}
                  onClick={closeDrawer}
                >
                  Our Story
                </Link>
              </li>
            </>
          )}
          <li>
            <Link
              to="/travel"
              className={location.pathname === "/travel" ? "active" : ""}
              onClick={closeDrawer}
            >
              Travel
            </Link>
          </li>
          <li>
            <Link
              to="/wedding-party"
              className={location.pathname === "/wedding-party" ? "active" : ""}
              onClick={closeDrawer}
            >
              Wedding Party
            </Link>
          </li>
          <li>
            <Link
              to="/rsvp"
              className={location.pathname === "/rsvp" ? "active" : ""}
              onClick={closeDrawer}
            >
              RSVP
            </Link>
          </li>
          <li>
            <Link
              to="/faq"
              className={location.pathname === "/faq" ? "active" : ""}
              onClick={closeDrawer}
            >
              FAQ
            </Link>
          </li>
          <li>
            <a
              href="https://registry.theknot.com/benjamin-desollar-kelly-bush-august-2025-il/66866158"
              target="_blank"
              rel="noopener noreferrer"
              className={location.pathname === "/registry" ? "active" : ""}
              onClick={closeDrawer}
            >
              Registry
            </a>
          </li>
          {currentUser && (
            <li>
              <Link
                to="/admin"
                className={location.pathname === "/admin" ? "active" : ""}
                onClick={closeDrawer}
              >
                Dashboard
              </Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
