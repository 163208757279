import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  Container,
  Typography,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import * as XLSX from "xlsx";
import { guests } from "../constants";
import "../styles/AdminDashboard.css";

ChartJS.register(ArcElement, Tooltip, Legend);

const AdminDashboard = () => {
  const [rsvps, setRsvps] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const fetchRsvps = async () => {
      const rsvpCollection = collection(db, "rsvps");
      const rsvpSnapshot = await getDocs(rsvpCollection);
      const rsvpList = rsvpSnapshot.docs.map((doc) => doc.data());
      setRsvps(rsvpList);
    };

    fetchRsvps();
  }, [db]);

  const yesCount = rsvps.filter((rsvp) => rsvp.attending === "yes").length;
  const noCount = rsvps.filter((rsvp) => rsvp.attending === "no").length;
  const totalCount = rsvps.length;
  const yesPercentage = ((yesCount / totalCount) * 100).toFixed(2);
  const noPercentage = ((noCount / totalCount) * 100).toFixed(2);

  const pieData = {
    labels: ["Yes", "No"],
    datasets: [
      {
        data: [yesCount, noCount],
        backgroundColor: ["#4CAF50", "#F44336"], // Green for Yes, Red for No
      },
    ],
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rsvps);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "RSVPs");
    XLSX.writeFile(workbook, "RSVPs.xlsx");
  };

  const sendMassEmail = () => {
    const emails = rsvps.map((rsvp) => rsvp.email).join(",");
    window.location.href = `mailto:${emails}?subject=RSVP%20Update`;
  };

  const getRsvpStatus = (name) => {
    const rsvp = rsvps.find((rsvp) => rsvp.name === name);
    return rsvp ? rsvp.attending : "No response";
  };

  return (
    <Container className="admin-dashboard">
      <Typography variant="h2" gutterBottom>
        RSVP Overview
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Paper className="chart-container">
            <Typography variant="h6" gutterBottom>
              Attendance
            </Typography>
            <Pie data={pieData} />
            <Typography variant="body1" gutterBottom>
              Yes: {yesPercentage}% | No: {noPercentage}%
            </Typography>
            <Typography variant="body1" gutterBottom>
              Total Guest Answers: {totalCount}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <TableContainer component={Paper} className="rsvp-list">
        <Typography variant="h3" gutterBottom>
          RSVP List
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Attending</TableCell>
              <TableCell>Special Requests</TableCell>
              <TableCell>Comments</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rsvps.map((rsvp, index) => (
              <TableRow key={index}>
                <TableCell>{rsvp.name}</TableCell>
                <TableCell>{rsvp.email}</TableCell>
                <TableCell>{rsvp.phoneNumber}</TableCell>
                <TableCell>{rsvp.attending}</TableCell>
                <TableCell>{rsvp.specialRequests}</TableCell>
                <TableCell>{rsvp.comments}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button className="custom-button" onClick={exportToExcel}>
          Export to Excel
        </Button>
        <Button className="custom-button" onClick={sendMassEmail}>
          Send Mass Email
        </Button>
      </TableContainer>
      <TableContainer component={Paper} className="guest-list">
        <Typography variant="h3" gutterBottom>
          Guest List
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Plus One</TableCell>
              <TableCell>RSVP Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {guests.map((guest, index) => (
              <TableRow key={index}>
                <TableCell>{guest.name}</TableCell>
                <TableCell>{guest.plusOne}</TableCell>
                <TableCell>{getRsvpStatus(guest.name)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AdminDashboard;
