import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faUtensils,
  faLandmark,
  faTimes,
  faChurch,
} from "@fortawesome/free-solid-svg-icons";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/Travel.css";
import stRaymondChurch from "../assets/images/stRaymonds.jpg";
import wilderMansion from "../assets/images/wilderMansion.jpg";
import doubleTree from "../assets/images/doubleTree.png";
import westinHotel from "../assets/images/westinHotel.png";
import elmhurstArtMuseum from "../assets/images/elmhurstArtMuseum.jpeg";
import oakbrookCenter from "../assets/images/oakbrookCenter.jpg";

const Travel = () => {
  const [popupContent, setPopupContent] = useState(null);

  const handlePopup = (content) => {
    setPopupContent(content);
  };

  const closePopup = () => {
    setPopupContent(null);
  };

  const popupDetails = {
    Ceremony: [
      {
        img: stRaymondChurch,
        title: "St Raymond de Peñafort Catholic Church",
        description: "A beautiful church located in Mount Prospect, IL.",
        address: "301 S I Oka Ave, Mount Prospect, IL 60056",
        link: "https://www.st-raymond.org",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2962.2318746931783!2d-87.9412272!3d42.059649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fba079fa08033%3A0x28504f901812e2f2!2sSt%20Raymond%20of%20Pe%C3%B1afort%20Catholic%20Church!5e0!3m2!1sen!2sus!4v1720058950579!5m2!1sen!2sus",
      },
    ],
    Reception: [
      {
        img: wilderMansion,
        title: "Wilder Mansion",
        description: "A beautiful historic mansion in Elmhurst, IL.",
        address: "211 Prospect Ave, Elmhurst, IL 60126",
        link: "https://www.epd.org/facilities/wilder-mansion",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5939.744579906795!2d-87.94682062339787!3d41.89560356431736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e4cb1be311235%3A0x9b873d48acb13a6a!2sWilder%20Mansion!5e0!3m2!1sen!2sus!4v1720059328228!5m2!1sen!2sus",
      },
    ],
    Lodging: [
      {
        img: westinHotel,
        title: "The Westin Chicago Lombard",
        description:
          "Comfortable stay with a travel time of ~15-20 minutes from Wilder Mansion and ~20-25 minutes from St Raymond de Peñafort Catholic Church. Average price: $180/night.",
        address: "70 Yorktown Center, Lombard, IL 60148",
        link: "https://www.marriott.com/hotels/travel/chiwl-the-westin-chicago-lombard/",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2972.334718492536!2d-88.00511822391724!3d41.8426217712456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e4d8ccff5a285%3A0x14aed5b5562cbc1c!2sThe%20Westin%20Chicago%20Lombard!5e0!3m2!1sen!2sus!4v1723922338039!5m2!1sen!2sus",
      },
      {
        img: doubleTree,
        title: "DoubleTree by Hilton Hotel Chicago - Oak Brook",
        description:
          "Comfortable stay with a travel time of ~15 minutes from Wilder Mansion and ~25-30 minutes from St Raymond de Peñafort Catholic Church. Average price: $150/night.",
        address: "1909 Spring Rd, Oak Brook, IL 60523",
        link: "https://www.hilton.com/en/hotels/chicbdt-doubletree-chicago-oak-brook/",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2971.8729272632922!2d-87.94990912391674!3d41.852561871244504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e4c65e0076267%3A0xd5c81d475a263635!2sDoubleTree%20by%20Hilton%20Hotel%20Chicago%20-%20Oak%20Brook!5e0!3m2!1sen!2sus!4v1723922543743!5m2!1sen!2sus",
      },
    ],
    "Things to Do": [
      {
        img: elmhurstArtMuseum,
        title: "Elmhurst Art Museum",
        description: "Explore contemporary art and architecture.",
        address: "150 Cottage Hill Ave, Elmhurst, IL 60126",
        link: "https://www.elmhurstartmuseum.org",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2969.642776309028!2d-87.94335902339769!3d41.90053901400813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e4cb273f9d4ed%3A0xaed50798347f4ee4!2sElmhurst%20Art%20Museum!5e0!3m2!1sen!2sus!4v1720065712465!5m2!1sen!2sus",
      },
      {
        img: oakbrookCenter,
        title: "Oakbrook Center",
        description: "A premier shopping destination with dining options.",
        address: "100 Oakbrook Center, Oak Brook, IL 60523",
        link: "https://www.oakbrookcenter.com",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2971.919016016914!2d-87.95775030936932!3d41.85156989294102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e4c6821cee655%3A0xd422ff4e3c8dec02!2sOakbrook%20Center!5e0!3m2!1sen!2sus!4v1720065792029!5m2!1sen!2sus",
      },
    ],
  };

  return (
    <div className="travel-page">
      <motion.div
        className="travel-container"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <h2 className="travel-title">Wedding Travel Guide</h2>
        <div className="travel-sections">
          <div
            className="travel-section"
            onClick={() => handlePopup("Ceremony")}
          >
            <FontAwesomeIcon icon={faChurch} className="travel-icon" />
            <span className="travel-text">Ceremony</span>
          </div>
          <div
            className="travel-section"
            onClick={() => handlePopup("Reception")}
          >
            <FontAwesomeIcon icon={faLandmark} className="travel-icon" />
            <span className="travel-text">Reception</span>
          </div>
          <div
            className="travel-section"
            onClick={() => handlePopup("Lodging")}
          >
            <FontAwesomeIcon icon={faBed} className="travel-icon" />
            <span className="travel-text">Hotels</span>
          </div>

          <div
            className="travel-section"
            onClick={() => handlePopup("Things to Do")}
          >
            <FontAwesomeIcon icon={faUtensils} className="travel-icon" />
            <span className="travel-text">Things to Do</span>
          </div>
        </div>

        <Dialog
          open={!!popupContent}
          onClose={closePopup}
          maxWidth="md"
          fullWidth
          classes={{ paper: "travel-dialog-paper" }}
        >
          <DialogTitle className="travel-dialog-title">
            {popupContent}
            <IconButton
              aria-label="close"
              onClick={closePopup}
              className="travel-dialog-close-button"
              sx={{ color: "black" }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </DialogTitle>
          <DialogContent className="travel-dialog-content">
            {Array.isArray(popupDetails[popupContent]) ? (
              <Carousel
                showThumbs={false}
                showStatus={false}
                infiniteLoop
                useKeyboardArrows
                className="custom-carousel"
              >
                {popupDetails[popupContent].map((item, index) => (
                  <div key={index} className="carousel-item">
                    <img
                      src={item.img}
                      alt={item.title}
                      className="carousel-image"
                    />
                    <Typography variant="h6" className="carousel-title">
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      className="carousel-description"
                    >
                      {item.description}
                    </Typography>
                    {item.link && (
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="carousel-link"
                      >
                        Learn More
                      </a>
                    )}
                    {item.address && (
                      <Typography variant="body2" className="carousel-address">
                        {item.address}
                      </Typography>
                    )}
                    {item.map && (
                      <iframe
                        src={item.map}
                        width="100%"
                        height="200"
                        style={{ border: 0, marginTop: "10px" }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title={item.title}
                      ></iframe>
                    )}
                  </div>
                ))}
              </Carousel>
            ) : (
              <Typography variant="body1">No details available.</Typography>
            )}
          </DialogContent>
        </Dialog>
      </motion.div>
    </div>
  );
};

export default Travel;
