import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../styles/FAQ.css";
import churchImage from "../assets/images/stRaymonds.jpg";
import mansionImage from "../assets/images/wilderMansion.jpg";

const FAQ = () => {
  const [expanded, setExpanded] = useState(false);

  const faqs = [
    {
      question: "Where will the ceremony and reception be held?",
      answer: (
        <>
          The ceremony will be held at St. Raymond de Peñafort Catholic Church
          in Mount Prospect, IL. The reception will take place at Wilder Mansion
          in Elmhurst, IL.
          <br />
          <br />
          <strong>Addresses:</strong>
          <br />
          St. Raymond de Peñafort Catholic Church
          <br />
          301 S. I Oka Ave, Mount Prospect, IL 60056
          <br />
          <img
            src={churchImage}
            alt="St. Raymond's Catholic Church"
            className="faq-image"
          />
          <br />
          Wilder Mansion
          <br />
          211 Prospect Ave, Elmhurst, IL 60126
          <br />
          <img src={mansionImage} alt="Wilder Mansion" className="faq-image" />
        </>
      ),
    },
    {
      question: "What is the dress code?",
      answer:
        "We would love to see our family and friends get dressed up for our big day! Dress code is formal attire. Ladies think floor-length, tea-length, or midi formal dresses. Gentlemen think suits, no jeans please. Summer colors are encouraged, but of course we want you to look and feel your best!",
    },
    {
      question: "Can I wear White, Ivory, or Cream Color attire?",
      answer: "No, we ask that you let the bride have her day!",
    },
    {
      question: "Are children allowed?",
      answer:
        "We love your little ones! However, we have decided to keep our wedding and reception an adult-only event with the exception of immediate family. We encourage you to use this evening as a date night!",
    },
    {
      question: "Is there parking available?",
      answer:
        "Yes, there is ample parking available at both the ceremony and reception venues.",
    },
    {
      question: "What time should I arrive?",
      answer:
        "Guests should arrive at St. Raymond's Catholic Church by 2:50 PM for the ceremony, and 5:00 PM for cocktail hour at Wilder Mansion.",
    },
    {
      question: "Will there be transportation provided?",
      answer:
        "No, transportation will not be provided between the church and the reception venue. We recommend using a ride-sharing service or carpooling with other guests. There will be a shuttle service from the venue back to the hotel if you book with our room block at the DoubleTree hotel.",
    },
    {
      question: "What should I do if I have any severe allergies?",
      answer:
        "Please let us know about any severe allergies you have when you RSVP. The groom also has a severe allergy to peanuts, tree nuts, and fish, so he already has you covered for most things!",
    },
    {
      question: "Can I bring a plus one?",
      answer:
        "Due to limited space, we are only able to accommodate the guests that are included on your invitation. Please refer to your invitation for details about bringing a plus one. If you have any questions, feel free to contact us.",
    },
    {
      question: "Will there be an open bar?",
      answer:
        "Yes, there will be an open bar during cocktail hour, and it will reopen after dinner for party time! During dinner we will keep the alcohol flowing with wine service. The drinks are on us and the hangover is on you, so get ready to celebrate!",
    },
    {
      question: "When do I need to RSVP by?",
      answer:
        "We ask that you RSVP by June 25th, 2025 to give our vendors ample time to prepare our event. Unfortuntanely, we may not be able to accommodate late RSVPs due to strict vendor deadlines.",
    },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <motion.div
      className="faq-page"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <Container maxWidth="md" className="faq-container">
        <Typography variant="h2" className="faq-title">
          FrequentlyAsked Questions
        </Typography>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleChange(index)}
            className="faq-accordion"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography className="faq-question">{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq-answer">{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </motion.div>
  );
};

export default FAQ;
