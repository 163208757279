import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import HeroSection from "./components/HeroSection";
import OurStory from "./components/OurStory";
import Travel from "./components/Travel";
import RSVP from "./components/RSVP";
import FAQ from "./components/FAQ";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import WeddingParty from "./components/WeddingParty";
import Login from "./components/Login";
import AdminDashboard from "./components/AdminDashboard";
import PasswordScreen from "./components/PasswordScreen";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import "./firebaseConfig"; // Ensure Firebase is initialized
import "./App.css"; // Import the new CSS file

const AppContent = () => {
  const { isPasswordVerified } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });

    return unsubscribe;
  }, []);

  if (!isPasswordVerified) {
    return <PasswordScreen />;
  }

  return (
    <div className="app-content">
      <Navbar />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<HeroSection />} />
          <Route path="/our-story" element={<OurStory />} />
          <Route path="/travel" element={<Travel />} />
          <Route path="/wedding-party" element={<WeddingParty />} />
          <Route path="/rsvp" element={<RSVP />} />
          <Route path="/faq" element={<FAQ />} />
          {/* <Route path="/registry" element={<Registry />} /> */}
          <Route path="/top-secret" element={<Login />} />
          <Route
            path="/admin"
            element={isAuthenticated ? <AdminDashboard /> : <Login />}
          />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

export default App;
